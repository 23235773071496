import {
    AfterViewInit,
    Component,
    inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { TasksService } from 'app/modules/admin/tasks/tasks.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        NotificationsComponent,
        UserComponent,
        MatIconModule,
        MatButtonModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        ShortcutsComponent,
        MessagesComponent,
        RouterOutlet,
        QuickChatComponent,
    ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private taskService = inject(TasksService);

    customeNav: FuseNavigationItem[] = [
        {
            icon: 'manage_accounts',
            id: 'users',
            link: '/users',
            title: 'User Management',
            type: 'collapsable',
            children: [
                {
                    id: 'roles',
                    link: '/roles',
                    title: 'Roles',
                    type: 'basic',
                },
                {
                    id: 'users',
                    link: '/users',
                    title: 'Users',
                    type: 'basic',
                }
            ]
        },
        {
            icon: 'book_online',
            id: 'ticketBooking',
            link: '/ticket-booking',
            title: 'Ticket Booking',
            type: 'basic',
        },
        {
            icon: 'chair',
            id: 'seat-layout',
            link: '/seat-layout',
            title: 'Seat Layout',
            type: 'basic',
        },
        {
            icon: 'heroicons_outline:users',
            id: 'customers',
            link: '/customers',
            title: 'Customers',
            type: 'basic',
        },
        {
            id: 'cms',
            title: 'Website',
            type: 'collapsable',
            icon: 'heroicons_outline:cog',
            link: '/cms',
            children: [],
        },
        {
            id: 'packages',
            title: 'Packages',
            type: 'basic',
            icon: 'heroicons_outline:cube',
            link: '/packages',
        },
        {
            id: 'products',
            title: 'Foods',
            type: 'collapsable',
            icon: 'food_bank',
            children: [
                {
                    id: 'categories',
                    title: 'Categories',
                    type: 'basic',
                    link: '/inventory/categories',
                },
                {
                    id: 'ingredients',
                    title: 'Ingredients',
                    type: 'basic',
                    link: '/inventory/ingredients',
                },
                {
                    id: 'products',
                    title: 'Foods',
                    type: 'basic',
                    link: '/inventory/products',
                },
            ]
        },
        {
            id: 'ticket-management',
            title: 'Ticket Management',
            type: 'collapsable',
            icon: 'dashboard_customize',
            children: [
                {
                    id: 'ticket-schedule',
                    title: 'Ticket Schedule',
                    type: 'basic',
                    link: '/ticket/schedule',
                },
                {
                    id: 'shifts',
                    title: 'Shifts',
                    type: 'basic',
                    link: '/shifts',
                },
                {
                    id: 'floor',
                    title: 'Floor',
                    type: 'basic',
                    link: '/floor',
                },
            ],
        },
        {
            id: 'ticket_order',
            title: 'Ticket Order',
            type: 'collapsable',
            icon: 'heroicons_outline:ticket',
            children: [
                {
                    id: 'order',
                    title: 'Order',
                    type: 'basic',
                    link: '/order',
                },
                {
                    id: 'order-list',
                    title: 'Order List',
                    type: 'basic',
                    link: '/order-list',
                },
            ],
        },
        {
            id: 'customer-dashboard',
            title: 'Customer Dashboard',
            type: 'basic',
            icon: 'heroicons_outline:cube',
            link: '/customer-dashboard',
        },
        {
            id: 'transport_management',
            title: 'Transport',
            type: 'collapsable',
            icon: 'heroicons_outline:truck',
            children: [
                {
                    id: 'area_pricing',
                    title: 'Area & Pricing',
                    type: 'basic',
                    link: '/area-pricing',
                },
                {
                    id: 'vehicles',
                    title: 'Vehicles',
                    type: 'basic',
                    link: '/vehicles',
                },
            ],
        },
        // {
        //     id: 'rbac',
        //     title: 'RBAC',
        //     type: 'collapsable',
        //     icon: 'heroicons_outline:finger-print',
        //     children: [
        //         {
        //             id: 'features',
        //             title: 'Feature 1',
        //             type: 'basic',
        //             icon: 'heroicons_outline:cog',
        //             link: '/f1',
        //         },
        //         {
        //             id: 'features',
        //             title: 'Feature 2',
        //             type: 'basic',
        //             icon: 'heroicons_outline:cog',
        //             link: '/f2',
        //         },
        //     ],
        // },
    ];

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this.taskService.getScreen().subscribe((screen: any) => {
            let cmsNav = this.customeNav.find((nav) => nav.id === 'cms');
            cmsNav.children = screen?.map((s) => {
                return {
                    id: s,
                    title: this.makeFirstLetterUppercase(s),
                    type: 'basic',
                    link: `/cms/${s}`,
                };
            });
        });
    }

    makeFirstLetterUppercase(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                console.log(navigation);
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngAfterViewInit() { }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
