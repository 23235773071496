import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { SeatLayoutModule } from './modules/admin/seat-layout/seat-layout.module';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'users' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'users', pathMatch: 'full', redirectTo: 'users' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.routes'
                    ),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.routes'
                    ),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.routes'
                    ),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.routes'),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            {
                path: 'example',
                loadChildren: () =>
                    import('app/modules/admin/example/example.routes'),
            },
            // ticket-booking TicketBookingModule
            {
                path: 'ticket-booking',
                loadChildren: () =>
                    import(
                        './modules/admin/ticket-booking/ticket-booking.module'
                    ).then((m) => m.TicketBookingModule),
            },
            // {
            //     path: 'users',
            //     loadChildren: () =>
            //         import('app/modules/admin/contacts/contacts.routes'),
            // },
            {
                path: 'cms',
                loadChildren: () =>
                    import('app/modules/admin/tasks/tasks.routes'),
            },
            {
                path: 'packages',
                loadChildren: () =>
                    import('app/modules/admin/packages/packages.routes'),
            },
            {
                path: 'shifts',
                loadChildren: () =>
                    import('app/modules/admin/shifts/shifts.routes'),
            },
            {
                path: 'ticket',
                loadChildren: () =>
                    import(
                        './modules/admin/ticket-schedule/ticket-schedule.module'
                    ).then((m) => m.TicketScheduleModule),
            },
            {
                path: 'seat-layout',
                loadChildren: () =>
                    import(
                        './modules/admin/seat-layout/seat-layout.module'
                        ).then((m) => m.SeatLayoutModule),
            },
            {
                path: 'order',
                loadChildren: () =>
                    import('./modules/admin/order/order.module').then(
                        (m) => m.OrderModule
                    ),
            },
            {
                path: 'order-list',
                loadChildren: () =>
                    import('./modules/admin/order-list/order-list.module').then(
                        (m) => m.OrderListModule
                    ),
            },
            {
                path: 'customer-dashboard',
                loadChildren: () =>
                    import(
                        './modules/admin/customer-dashboard/customer-dashboard.module'
                    ).then((m) => m.CustomerDashboardModule),
            },

            {
                path: 'vehicles',
                loadChildren: () =>
                    import('app/modules/admin/transport/transport.routes'),
            },
            {
                path: 'area-pricing',
                loadChildren: () =>
                    import(
                        'app/modules/admin/area-pricing/area-pricing.routes'
                    ),
            },
            { path: 'floor', loadComponent: () => import('app/modules/admin/floor/floor.component').then(m => m.FloorComponent) },
            { path: 'ground-floor', loadComponent: () => import('app/modules/admin/floor/ground-floor/ground-floor.component').then(m => m.GroundFloorComponent) },
            { path: 'roles', loadComponent: () => import('app/modules/admin/roles/roles.component').then(m => m.RolesComponent) },
            { path: 'users', loadComponent: () => import('app/modules/admin/users/users.component').then(m => m.UsersComponent) },
            {
                path: 'customers', loadComponent: () => import('app/modules/admin/customers/customers.component').then(m => m.CustomersComponent)
            },
            {
                path: 'inventory',
                loadChildren: () => import('app/modules/admin/inventory/inventory.routes'),
            },
        ],
    },
];
